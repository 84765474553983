import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const ProcessingDataIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="191" height="177" viewBox="0 0 191 177" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="70" fill={theme.colors.selection} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 28C48 26.8954 48.8954 26 50 26H87.5569C88.0638 26 88.5518 26.1925 88.9222 26.5385L103.365 40.0315C103.77 40.4097 104 40.9389 104 41.4929V98C104 99.1046 103.105 100 102 100H50C48.8954 100 48 99.1046 48 98V28Z"
        fill="url(#paint0_linear_4901_335065)"
      />
      <g filter="url(#filter0_d_4901_335065)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 34C40 32.8954 40.8954 32 42 32H81.0027C81.5067 32 81.9921 32.1903 82.3618 32.5328L97.3592 46.426C97.7678 46.8045 98 47.3362 98 47.8932V106C98 107.105 97.1046 108 96 108H42C40.8954 108 40 107.105 40 106V34Z"
          fill="url(#paint1_linear_4901_335065)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53 51C51.3431 51 50 52.3431 50 54C50 55.6569 51.3431 57 53 57H85C86.6569 57 88 55.6569 88 54C88 52.3431 86.6569 51 85 51H53ZM53 62C51.3431 62 50 63.3431 50 65C50 66.6569 51.3431 68 53 68H85C86.6569 68 88 66.6569 88 65C88 63.3431 86.6569 62 85 62H53ZM50 76C50 74.3431 51.3431 73 53 73H85C86.6569 73 88 74.3431 88 76C88 77.6569 86.6569 79 85 79H53C51.3431 79 50 77.6569 50 76ZM53 84C51.3431 84 50 85.3431 50 87C50 88.6569 51.3431 90 53 90H75.5C77.1569 90 78.5 88.6569 78.5 87C78.5 85.3431 77.1569 84 75.5 84H53Z"
        fill="url(#paint2_linear_4901_335065)"
      />
      <g filter="url(#filter1_d_4901_335065)">
        <circle cx="133" cy="105" r="23" fill="url(#paint3_linear_4901_335065)" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143 105C143 110.523 138.523 115 133 115C127.477 115 123 110.523 123 105C123 99.4772 127.477 95 133 95C138.523 95 143 99.4772 143 105ZM134 99C134 98.4477 133.552 98 133 98C132.448 98 132 98.4477 132 99V105C132 105.379 132.214 105.725 132.553 105.894L136.553 107.894C137.047 108.141 137.647 107.941 137.894 107.447C138.141 106.953 137.941 106.353 137.447 106.106L134 104.382V99Z"
        fill="url(#paint4_linear_4901_335065)"
      />
      <defs>
        <filter
          id="filter0_d_4901_335065"
          x="22"
          y="23"
          width="94"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="9" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.945098 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4901_335065" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4901_335065" result="shape" />
        </filter>
        <filter
          id="filter1_d_4901_335065"
          x="75.6521"
          y="61.3913"
          width="114.696"
          height="114.696"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.7392" />
          <feGaussianBlur stdDeviation="17.1739" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.114792 0 0 0 0 0.258462 0 0 0 0 0.3625 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4901_335065" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4901_335065" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_4901_335065"
          x1="69.7241"
          y1="100"
          x2="92.837"
          y2="40.7257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary.c100} />
          <stop offset="1" stopColor={theme.colors.primary.c050} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4901_335065"
          x1="55.8942"
          y1="96.6731"
          x2="83.5416"
          y2="40.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4901_335065"
          x1="54.8396"
          y1="97.1855"
          x2="33.2735"
          y2="66.5477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary.c100} />
          <stop offset="1" stopColor={theme.colors.primary.c050} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4901_335065"
          x1="110.66"
          y1="103.193"
          x2="142.533"
          y2="124.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c400} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4901_335065"
          x1="140.88"
          y1="107.829"
          x2="119.365"
          y2="101.286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProcessingDataIcon;
