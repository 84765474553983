import React from 'react';
import Lottie from 'react-lottie-player';
import styled from 'styled-components';

import animationJSON from '~/assets/visitiq-loader-v1.json';
import { useBrandContext } from '~/brands/BrandContext';

export const Container = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

export const BrandLogoContainer = styled.div`
  margin: auto;
`;

export const StyledLottie = styled(Lottie)`
  margin: auto;
  width: 8rem;
  height: 8rem;
`;

const LoadingAnimation = () => {
  const { isBrandChecked, brand } = useBrandContext();
  return (
    <Container>
      {isBrandChecked &&
        (brand ? (
          <BrandLogoContainer>{brand?.logo}</BrandLogoContainer>
        ) : (
          <StyledLottie loop animationData={animationJSON} play />
        ))}
    </Container>
  );
};

export default LoadingAnimation;
