import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const AudiencePlotEmptyState = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="243" height="183" viewBox="0 0 243 183" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.06" cx="121" cy="84" r="84" fill={theme.colors.graph[3]} />
      <g filter="url(#filter0_d_4362_43279)">
        <rect
          x="22.2177"
          y="18.2177"
          width="122.667"
          height="98.5647"
          rx="2.39431"
          fill="white"
          stroke="#F4F4F4"
          strokeWidth="0.435328"
        />
        <rect
          opacity="0.2"
          x="32.4497"
          y="31.0598"
          width="36.1322"
          height="3.91795"
          rx="1.95898"
          fill="url(#paint0_linear_4362_43279)"
        />
        <rect
          x="32.4453"
          y="99.6094"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint1_linear_4362_43279)"
        />
        <rect
          x="32.4453"
          y="99.6094"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint2_linear_4362_43279)"
        />
        <rect
          opacity="0.2"
          x="40.2822"
          y="100.915"
          width="14.3658"
          height="2.61197"
          rx="1.30598"
          fill="url(#paint3_linear_4362_43279)"
        />
        <rect
          x="63.7944"
          y="99.6077"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint4_linear_4362_43279)"
        />
        <rect
          opacity="0.2"
          x="71.6313"
          y="100.913"
          width="9.57722"
          height="2.61197"
          rx="1.30598"
          fill="url(#paint5_linear_4362_43279)"
        />
        <rect
          x="90.3457"
          y="99.6084"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint6_linear_4362_43279)"
        />
        <rect
          opacity="0.2"
          x="98.1826"
          y="100.914"
          width="19.1544"
          height="2.61197"
          rx="1.30598"
          fill="url(#paint7_linear_4362_43279)"
        />
        <rect
          x="129.922"
          y="46.5408"
          width="10.7027"
          height="97.2162"
          rx="2.61197"
          transform="rotate(90 129.922 46.5408)"
          fill="url(#paint8_linear_4362_43279)"
        />
        <rect
          x="104.949"
          y="61.7021"
          width="11.5946"
          height="72.2432"
          rx="2.61197"
          transform="rotate(90 104.949 61.7021)"
          fill="url(#paint9_linear_4362_43279)"
        />
        <rect
          x="79.0776"
          y="77.7563"
          width="11.5946"
          height="46.3784"
          rx="2.61197"
          transform="rotate(90 79.0776 77.7563)"
          fill="url(#paint10_linear_4362_43279)"
        />
      </g>
      <g filter="url(#filter1_d_4362_43279)">
        <rect
          x="97.2177"
          y="51.2177"
          width="123.565"
          height="98.5647"
          rx="2.39431"
          fill="white"
          stroke="#F4F4F4"
          strokeWidth="0.435328"
        />
        <path
          d="M118 93.4987C118 91.9987 109 119.499 108.5 120.999L209.5 121V78L193.5 103.999L177 95.4987L160.5 105.499L146.5 98.4987L135.5 108.999L118 93.4987Z"
          fill="url(#paint11_linear_4362_43279)"
        />
        <path
          d="M108.5 118.999C108.953 117.641 116.372 96.6136 117.773 93.7568C117.899 93.4998 118.123 93.6087 118.337 93.7985L134.812 108.39C135.201 108.735 135.789 108.724 136.165 108.365L145.991 98.9856C146.296 98.6943 146.752 98.6258 147.129 98.8145L160.006 105.253C160.314 105.407 160.678 105.392 160.972 105.214L176.521 95.7901C176.819 95.6099 177.188 95.5971 177.498 95.7563L192.627 103.55C193.112 103.8 193.707 103.615 193.966 103.135L207.5 78"
          stroke="url(#paint12_linear_4362_43279)"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <rect
          opacity="0.2"
          x="107.45"
          y="64.0598"
          width="36.1322"
          height="3.91795"
          rx="1.95898"
          fill="url(#paint13_linear_4362_43279)"
        />
        <rect
          x="107.445"
          y="132.609"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint14_linear_4362_43279)"
        />
        <rect
          x="107.445"
          y="132.609"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint15_linear_4362_43279)"
        />
        <rect
          opacity="0.2"
          x="115.282"
          y="133.915"
          width="14.3658"
          height="2.61197"
          rx="1.30598"
          fill="url(#paint16_linear_4362_43279)"
        />
        <rect
          x="138.794"
          y="132.608"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint17_linear_4362_43279)"
        />
        <rect
          opacity="0.2"
          x="146.631"
          y="133.913"
          width="9.57722"
          height="2.61197"
          rx="1.30598"
          fill="url(#paint18_linear_4362_43279)"
        />
        <rect
          x="165.346"
          y="132.608"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          fill="url(#paint19_linear_4362_43279)"
        />
        <rect
          opacity="0.2"
          x="173.183"
          y="133.914"
          width="19.1544"
          height="2.61197"
          rx="1.30598"
          fill="url(#paint20_linear_4362_43279)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4362_43279"
          x="0.594595"
          y="7.2973"
          width="165.913"
          height="141.811"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.7027" />
          <feGaussianBlur stdDeviation="10.7027" />
          <feColorMatrix type="matrix" values={theme.colors.backgroundShadowGradientValue} />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4362_43279" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4362_43279" result="shape" />
        </filter>
        <filter
          id="filter1_d_4362_43279"
          x="75.5946"
          y="40.2973"
          width="166.811"
          height="141.811"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.7027" />
          <feGaussianBlur stdDeviation="10.7027" />
          <feColorMatrix type="matrix" values={theme.colors.backgroundShadowGradientValue} />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4362_43279" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4362_43279" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_4362_43279"
          x1="43.06"
          y1="32.3658"
          x2="93.8408"
          y2="34.3817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4362_43279"
          x1="36.1767"
          y1="105.073"
          x2="34.1791"
          y2="97.8466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADA3B8" />
          <stop offset="1" stopColor="#C5B6D5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4362_43279"
          x1="39.2245"
          y1="102.983"
          x2="36.7548"
          y2="96.6341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[3]} />
          <stop offset="1" stopColor={theme.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4362_43279"
          x1="44.5008"
          y1="101.785"
          x2="64.7112"
          y2="102.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4362_43279"
          x1="67.2771"
          y1="104.437"
          x2="66.8982"
          y2="98.9664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[6]} />
          <stop offset="1" stopColor={theme.colors.graph[6]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4362_43279"
          x1="74.4437"
          y1="101.784"
          x2="87.9215"
          y2="101.996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4362_43279"
          x1="95.1338"
          y1="103.617"
          x2="92.721"
          y2="98.3899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[8]} />
          <stop offset="1" stopColor={theme.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4362_43279"
          x1="103.807"
          y1="101.784"
          x2="130.743"
          y2="102.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4362_43279"
          x1="146.882"
          y1="135.46"
          x2="100.626"
          y2="121.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[3]} />
          <stop offset="1" stopColor={theme.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4362_43279"
          x1="112.679"
          y1="128.488"
          x2="85.0261"
          y2="64.4041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[6]} />
          <stop offset="1" stopColor={theme.colors.graph[6]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4362_43279"
          x1="89.7049"
          y1="113.344"
          x2="66.1314"
          y2="100.577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[8]} />
          <stop offset="1" stopColor={theme.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4362_43279"
          x1="126"
          y1="78"
          x2="126"
          y2="121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[3]} stopOpacity="0.2" />
          <stop offset="1" stopColor={theme.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4362_43279"
          x1="226.5"
          y1="109"
          x2="220.221"
          y2="54.9449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[3]} />
          <stop offset="1" stopColor={theme.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4362_43279"
          x1="118.06"
          y1="65.3658"
          x2="168.841"
          y2="67.3817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4362_43279"
          x1="111.177"
          y1="138.073"
          x2="109.179"
          y2="130.847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADA3B8" />
          <stop offset="1" stopColor="#C5B6D5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4362_43279"
          x1="114.224"
          y1="135.983"
          x2="111.755"
          y2="129.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[3]} />
          <stop offset="1" stopColor={theme.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4362_43279"
          x1="119.501"
          y1="134.785"
          x2="139.711"
          y2="135.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4362_43279"
          x1="142.277"
          y1="137.437"
          x2="141.898"
          y2="131.966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[6]} />
          <stop offset="1" stopColor={theme.colors.graph[6]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_4362_43279"
          x1="149.444"
          y1="134.784"
          x2="162.922"
          y2="134.996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4362_43279"
          x1="170.134"
          y1="136.617"
          x2="167.721"
          y2="131.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.graph[8]} />
          <stop offset="1" stopColor={theme.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4362_43279"
          x1="178.807"
          y1="134.784"
          x2="205.743"
          y2="135.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AudiencePlotEmptyState;
