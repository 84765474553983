import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const EmptyState = (props: any) => {
  const theme = useContext(ThemeContext);
  return (
    <svg height="156" width="216" fill="none" viewBox="0 0 216 156" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="95" cy="70" fill={theme.colors.selection} r="70" />
      <g>
        <rect height="44" width="145" fill="white" rx="4.79339" x="22" y="14" />
        <rect height="44" width="145" fill="url(#paint0_linear_1170_4373)" rx="4.79339" x="22" y="14" />
        <rect
          height="43.2698"
          width="144.27"
          rx="4.42827"
          stroke="#F4F4F4"
          strokeWidth="0.730243"
          x="22.3651"
          y="14.3651"
        />
      </g>
      <g>
        <rect height="44" width="145" fill="white" rx="4.79339" x="22" y="66" />
        <rect height="44" width="145" fill="url(#paint3_linear_1170_4373)" rx="4.79339" x="22" y="66" />
        <rect
          height="43.2698"
          width="144.27"
          rx="4.42827"
          stroke="#F4F4F4"
          strokeWidth="0.730243"
          x="22.3651"
          y="66.3651"
        />
      </g>
      <g>
        <circle cx="158" cy="105" fill="url(#paint6_linear_1170_4373)" r="23" />
      </g>
      <rect height="7" width="84" fill="url(#paint1_linear_1170_4373)" opacity="0.7" rx="3.5" x="66" y="32" />
      <rect height="24" width="24" fill={theme.colors.primary.c500} opacity="0.1" rx="4" x="32" y="24" />
      <rect height="7" width="84" fill="url(#paint4_linear_1170_4373)" opacity="0.7" rx="3.5" x="66" y="84" />
      <rect height="24" width="24" fill={theme.colors.primary.c500} opacity="0.1" rx="4" x="32" y="76" />
      <path
        d="M51 36C51 39.866 47.866 43 44 43C40.134 43 37 39.866 37 36C37 32.134 40.134 29 44 29C47.866 29 51 32.134 51 36ZM42 34H46V38H42V34Z"
        fill="url(#paint2_linear_1170_4373)"
        fillRule="evenodd"
      />
      <path
        d="M44 95C47.866 95 51 91.866 51 88C51 84.134 47.866 81 44 81C40.134 81 37 84.134 37 88C37 91.866 40.134 95 44 95Z"
        fill="url(#paint5_linear_1170_4373)"
      />
      <path
        d="M44 82C45.876 83.643 46.9421 85.7752 47 88C46.9421 90.2248 45.876 92.357 44 94C42.124 92.357 41.0579 90.2248 41 88C41.0579 85.7752 42.124 83.643 44 82Z"
        stroke={theme.colors.primaryLight}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M38 88C38 88 41.6487 88.5 44 88.5C46.3513 88.5 50 88 50 88"
        stroke={theme.colors.primaryLight}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M164 95C162.895 95 162 95.8954 162 97V112C162 113.105 162.895 114 164 114H165C166.105 114 167 113.105 167 112V97C167 95.8954 166.105 95 165 95H164ZM155 102C155 100.895 155.895 100 157 100H158C159.105 100 160 100.895 160 102V112C160 113.105 159.105 114 158 114H157C155.895 114 155 113.105 155 112V102ZM148 107C148 105.895 148.895 105 150 105H151C152.105 105 153 105.895 153 107V112C153 113.105 152.105 114 151 114H150C148.895 114 148 113.105 148 112V107Z"
        fill="url(#paint7_linear_1170_4373)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="61.7356"
          x2="66.2666"
          y1="51.4423"
          y2="11.335"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="64.6667"
          x2="158.046"
          y1="38.4167"
          y2="22.5223"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="49.8337"
          x2="51.4836"
          y1="42.8261"
          y2="26.4289"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="61.7356"
          x2="66.2666"
          y1="103.442"
          y2="63.335"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="64.6667"
          x2="158.046"
          y1="90.4167"
          y2="74.5223"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="49.8337"
          x2="51.4836"
          y1="94.8261"
          y2="78.4289"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="135.66"
          x2="167.533"
          y1="103.193"
          y2="124.606"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c400} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1170_4373"
          gradientUnits="userSpaceOnUse"
          x1="152"
          x2="168.695"
          y1="99.5"
          y2="116.871"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyState;
