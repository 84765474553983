import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const WelcomeImage = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <svg width="371" height="200" viewBox="0 0 371 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1837_12119)">
        <rect
          x="122.228"
          y="28.2277"
          width="113.414"
          height="132.545"
          rx="2.50514"
          fill="white"
          stroke={themeContext.colors.primary.c050}
          strokeWidth="0.455479"
        />
        <rect
          opacity="0.6"
          x="132.932"
          y="41.6644"
          width="37.8048"
          height="4.09932"
          rx="2.04966"
          fill="url(#paint0_linear_1837_12119)"
        />
        <rect
          x="148.873"
          y="142.325"
          width="5.46575"
          height="5.46575"
          rx="1.82192"
          fill="url(#paint1_linear_1837_12119)"
        />
        <rect
          opacity="0.4"
          x="157.072"
          y="143.692"
          width="20.0411"
          height="2.73288"
          rx="1.36644"
          fill="url(#paint2_linear_1837_12119)"
        />
        <rect
          x="184.401"
          y="142.325"
          width="5.46575"
          height="5.46575"
          rx="1.82192"
          fill="url(#paint3_linear_1837_12119)"
        />
        <rect
          opacity="0.4"
          x="192.599"
          y="143.692"
          width="20.0411"
          height="2.73288"
          rx="1.36644"
          fill="url(#paint4_linear_1837_12119)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.496 94.0477C142.496 75.1445 156.889 59.603 175.314 57.7842C176.459 57.6711 177.42 58.5959 177.42 59.7465V74.1424C177.42 75.1956 176.608 76.0606 175.573 76.2552C167.182 77.8322 160.833 85.1986 160.833 94.0477C160.833 100.514 164.223 106.188 169.322 109.391C170.26 109.979 170.692 111.163 170.225 112.167L164.178 125.132C163.703 126.151 162.486 126.593 161.5 126.054C150.177 119.872 142.496 107.857 142.496 94.0477Z"
          fill="url(#paint5_linear_1837_12119)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166.981 126.019C166.483 127.088 166.998 128.359 168.125 128.707C171.525 129.756 175.137 130.321 178.882 130.321C199.008 130.321 215.323 114.005 215.323 93.8792C215.323 74.9759 200.93 59.4344 182.505 57.6156C181.36 57.5026 180.4 58.4273 180.4 59.578V73.9739C180.4 75.027 181.211 75.892 182.246 76.0866C190.638 77.6637 196.986 85.03 196.986 93.8792C196.986 103.878 188.881 111.984 178.882 111.984C177.74 111.984 176.623 111.878 175.54 111.676C174.56 111.493 173.545 111.943 173.123 112.847L166.981 126.019Z"
          fill="url(#paint6_linear_1837_12119)"
        />
      </g>
      <g filter="url(#filter1_d_1837_12119)">
        <rect
          x="224.241"
          y="45.2386"
          width="122.667"
          height="98.5647"
          rx="2.39431"
          transform="rotate(5.82458 224.241 45.2386)"
          fill="white"
          stroke={themeContext.colors.primary.c050}
          strokeWidth="0.435328"
        />
        <rect
          opacity="0.6"
          x="233.116"
          y="59.0527"
          width="36.1322"
          height="3.91795"
          rx="1.95898"
          transform="rotate(5.82458 233.116 59.0527)"
          fill="url(#paint7_linear_1837_12119)"
        />
        <rect
          x="226.159"
          y="127.247"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          transform="rotate(5.82458 226.159 127.247)"
          fill="url(#paint8_linear_1837_12119)"
        />
        <rect
          x="226.159"
          y="127.247"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          transform="rotate(5.82458 226.159 127.247)"
          fill="url(#paint9_linear_1837_12119)"
        />
        <rect
          opacity="0.4"
          x="233.822"
          y="129.342"
          width="14.3658"
          height="2.61197"
          rx="1.30598"
          transform="rotate(5.82458 233.822 129.342)"
          fill="url(#paint10_linear_1837_12119)"
        />
        <rect
          x="257.341"
          y="130.428"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          transform="rotate(5.82458 257.341 130.428)"
          fill="url(#paint11_linear_1837_12119)"
        />
        <rect
          opacity="0.4"
          x="265.004"
          y="132.522"
          width="9.57722"
          height="2.61197"
          rx="1.30598"
          transform="rotate(5.82458 265.004 132.522)"
          fill="url(#paint12_linear_1837_12119)"
        />
        <rect
          x="283.759"
          y="133.123"
          width="5.22394"
          height="5.22394"
          rx="1.74131"
          transform="rotate(5.82458 283.759 133.123)"
          fill="url(#paint13_linear_1837_12119)"
        />
        <rect
          opacity="0.4"
          x="291.421"
          y="135.217"
          width="19.1544"
          height="2.61197"
          rx="1.30598"
          transform="rotate(5.82458 291.421 135.217)"
          fill="url(#paint14_linear_1837_12119)"
        />
        <rect
          x="328.512"
          y="84.3451"
          width="10.7027"
          height="97.2162"
          rx="2.61197"
          transform="rotate(95.8246 328.512 84.3451)"
          fill="url(#paint15_linear_1837_12119)"
        />
        <rect
          x="302.13"
          y="96.8947"
          width="11.5946"
          height="72.2432"
          rx="2.61197"
          transform="rotate(95.8246 302.13 96.8947)"
          fill="url(#paint16_linear_1837_12119)"
        />
        <rect
          x="274.769"
          y="110.241"
          width="11.5946"
          height="46.3784"
          rx="2.61197"
          transform="rotate(95.8246 274.769 110.241)"
          fill="url(#paint17_linear_1837_12119)"
        />
      </g>
      <g filter="url(#filter2_d_1837_12119)">
        <rect
          x="24.3379"
          y="70.2676"
          width="120.406"
          height="38.3906"
          rx="3.35156"
          transform="rotate(-6.6434 24.3379 70.2676)"
          fill="white"
          stroke={themeContext.colors.primary.c050}
          strokeWidth="0.609375"
        />
        <rect
          opacity="0.6"
          x="63.7115"
          y="82.4899"
          width="63"
          height="6"
          rx="3"
          transform="rotate(-6.6434 63.7115 82.4899)"
          fill="url(#paint18_linear_1837_12119)"
        />
        <rect
          opacity="0.1"
          x="35.0897"
          y="78.7762"
          width="19.5"
          height="19.5"
          rx="3.65625"
          transform="rotate(-6.6434 35.0897 78.7762)"
          fill={themeContext.colors.primary.c500}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.7595 86.8242C47.5352 86.3319 48.0043 85.4246 47.8909 84.4509C47.7331 83.0956 46.5064 82.1249 45.1511 82.2828C43.7958 82.4406 42.8251 83.6673 42.9829 85.0226C43.0963 85.9962 43.7614 86.7714 44.6294 87.0723C43.2861 87.6843 42.4288 89.1113 42.6088 90.6564C42.832 92.5731 49.773 91.7647 49.5497 89.848C49.3698 88.3029 48.2075 87.1112 46.7595 86.8242Z"
          fill="url(#paint19_linear_1837_12119)"
        />
      </g>
      <g filter="url(#filter3_d_1837_12119)">
        <rect
          x="29.7754"
          y="116.952"
          width="120.406"
          height="38.3906"
          rx="3.35156"
          transform="rotate(-6.6434 29.7754 116.952)"
          fill="white"
          stroke={themeContext.colors.primary.c050}
          strokeWidth="0.609375"
        />
        <rect
          opacity="0.6"
          x="69.149"
          y="129.174"
          width="63"
          height="6"
          rx="3"
          transform="rotate(-6.6434 69.149 129.174)"
          fill="url(#paint20_linear_1837_12119)"
        />
        <rect
          opacity="0.1"
          x="40.5272"
          y="125.461"
          width="19.5"
          height="19.5"
          rx="3.65625"
          transform="rotate(-6.6434 40.5272 125.461)"
          fill={themeContext.colors.primary.c500}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.197 133.509C52.9727 133.016 53.4418 132.109 53.3284 131.135C53.1706 129.78 51.9439 128.809 50.5886 128.967C49.2333 129.125 48.2626 130.352 48.4204 131.707C48.5338 132.681 49.1989 133.456 50.0669 133.757C48.7236 134.369 47.8663 135.796 48.0463 137.341C48.2695 139.257 55.2105 138.449 54.9872 136.532C54.8073 134.987 53.645 133.796 52.197 133.509Z"
          fill="url(#paint21_linear_1837_12119)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1837_12119"
          x="98"
          y="16"
          width="161.87"
          height="181"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values={themeContext.colors.backgroundShadowGradientValue} />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1837_12119" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1837_12119" result="shape" />
        </filter>
        <filter
          id="filter1_d_1837_12119"
          x="192.846"
          y="34.5487"
          width="174.822"
          height="153.29"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.7027" />
          <feGaussianBlur stdDeviation="10.7027" />
          <feColorMatrix type="matrix" values={themeContext.colors.backgroundShadowGradientValue} />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1837_12119" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1837_12119" result="shape" />
        </filter>
        <filter
          id="filter2_d_1837_12119"
          x="0.398071"
          y="44.3981"
          width="171.919"
          height="99.9422"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values={themeContext.colors.backgroundShadowGradientValue} />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1837_12119" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1837_12119" result="shape" />
        </filter>
        <filter
          id="filter3_d_1837_12119"
          x="5.83557"
          y="91.0824"
          width="171.919"
          height="99.9422"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values={themeContext.colors.backgroundShadowGradientValue} />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1837_12119" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1837_12119" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1837_12119"
          x1="144.033"
          y1="43.0308"
          x2="197.164"
          y2="45.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1837_12119"
          x1="155.966"
          y1="145.855"
          x2="153.382"
          y2="139.212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[3]} />
          <stop offset="1" stopColor={themeContext.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1837_12119"
          x1="162.957"
          y1="144.603"
          x2="191.14"
          y2="145.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1837_12119"
          x1="189.41"
          y1="146.519"
          x2="187.541"
          y2="141.654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[8]} />
          <stop offset="1" stopColor={themeContext.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1837_12119"
          x1="198.484"
          y1="144.603"
          x2="226.666"
          y2="145.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1837_12119"
          x1="187.817"
          y1="102.015"
          x2="141.586"
          y2="41.4367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[3]} />
          <stop offset="1" stopColor={themeContext.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1837_12119"
          x1="211.274"
          y1="113.402"
          x2="179.163"
          y2="57.6062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[8]} />
          <stop offset="1" stopColor={themeContext.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1837_12119"
          x1="243.726"
          y1="60.3587"
          x2="294.507"
          y2="62.3745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1837_12119"
          x1="229.89"
          y1="132.711"
          x2="227.893"
          y2="125.484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADA3B8" />
          <stop offset="1" stopColor="#C5B6D5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1837_12119"
          x1="232.938"
          y1="130.62"
          x2="230.468"
          y2="124.272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[3]} />
          <stop offset="1" stopColor={themeContext.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1837_12119"
          x1="238.041"
          y1="130.212"
          x2="258.251"
          y2="130.691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1837_12119"
          x1="260.823"
          y1="135.257"
          x2="260.444"
          y2="129.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[7]} />
          <stop offset="1" stopColor={themeContext.colors.graph[7]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1837_12119"
          x1="267.816"
          y1="133.393"
          x2="281.294"
          y2="133.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1837_12119"
          x1="288.547"
          y1="137.131"
          x2="286.134"
          y2="131.904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[8]} />
          <stop offset="1" stopColor={themeContext.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1837_12119"
          x1="297.046"
          y1="136.088"
          x2="323.982"
          y2="136.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D2D2D" />
          <stop offset="1" stopColor="#2D2D2D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1837_12119"
          x1="345.472"
          y1="173.264"
          x2="299.216"
          y2="159.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[3]} />
          <stop offset="1" stopColor={themeContext.colors.graph[3]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1837_12119"
          x1="309.859"
          y1="163.681"
          x2="282.206"
          y2="99.5966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[7]} />
          <stop offset="1" stopColor={themeContext.colors.graph[7]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1837_12119"
          x1="285.396"
          y1="145.829"
          x2="261.823"
          y2="133.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.graph[8]} />
          <stop offset="1" stopColor={themeContext.colors.graph[8]} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1837_12119"
          x1="62.7115"
          y1="87.9899"
          x2="133.212"
          y2="77.4899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.primary.c500} />
          <stop offset="1" stopColor={themeContext.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1837_12119"
          x1="49.1253"
          y1="91.2361"
          x2="49.3427"
          y2="80.1364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.primary.c500} />
          <stop offset="1" stopColor={themeContext.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1837_12119"
          x1="68.149"
          y1="134.674"
          x2="138.649"
          y2="124.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.primary.c500} />
          <stop offset="1" stopColor={themeContext.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1837_12119"
          x1="54.5628"
          y1="137.92"
          x2="54.7802"
          y2="126.821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeContext.colors.primary.c500} />
          <stop offset="1" stopColor={themeContext.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WelcomeImage;
