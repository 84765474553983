import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const FileChooseIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="133" height="135" viewBox="0 0 90 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="45" fill={theme.colors.selection} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2686 18C30.2686 16.8954 31.164 16 32.2686 16H56.0486C56.5555 16 57.0435 16.1925 57.4139 16.5385L66.5116 25.0377C66.9164 25.4159 67.1462 25.9451 67.1462 26.4992V62.7312C67.1462 63.8358 66.2508 64.7312 65.1462 64.7312H32.2686C31.164 64.7312 30.2686 63.8358 30.2686 62.7312V18Z"
        fill="url(#paint0_linear_4144_277466)"
      />
      <g filter="url(#filter0_d_4144_277466)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 21.9501C25 20.8456 25.8954 19.9501 27 19.9501H51.7338C52.2378 19.9501 52.7232 20.1404 53.0929 20.4829L62.5539 29.2474C62.9625 29.6259 63.1947 30.1576 63.1947 30.7146V67.9984C63.1947 69.103 62.2993 69.9984 61.1947 69.9984H27C25.8954 69.9984 25 69.103 25 67.9984V21.9501Z"
          fill="url(#paint1_linear_4144_277466)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5596 32.462C32.4685 32.462 31.584 33.3465 31.584 34.4376C31.584 35.5287 32.4685 36.4132 33.5596 36.4132H54.6325C55.7236 36.4132 56.6081 35.5287 56.6081 34.4376C56.6081 33.3465 55.7236 32.462 54.6325 32.462H33.5596ZM33.5596 39.7063C32.4685 39.7063 31.584 40.5909 31.584 41.6819C31.584 42.773 32.4685 43.6575 33.5596 43.6575H54.6325C55.7236 43.6575 56.6081 42.773 56.6081 41.6819C56.6081 40.5909 55.7236 39.7063 54.6325 39.7063H33.5596ZM31.584 48.9263C31.584 47.8352 32.4685 46.9507 33.5596 46.9507H54.6325C55.7236 46.9507 56.6081 47.8352 56.6081 48.9263C56.6081 50.0173 55.7236 50.9018 54.6325 50.9018H33.5596C32.4685 50.9018 31.584 50.0173 31.584 48.9263ZM33.5596 54.1928C32.4685 54.1928 31.584 55.0773 31.584 56.1684C31.584 57.2595 32.4685 58.144 33.5596 58.144H48.3765C49.4676 58.144 50.3521 57.2595 50.3521 56.1684C50.3521 55.0773 49.4676 54.1928 48.3765 54.1928H33.5596Z"
        fill="url(#paint2_linear_4144_277466)"
      />
      <g filter="url(#filter1_d_4144_277466)">
        <circle cx="82" cy="70" r="16" fill="url(#paint3_linear_4144_277466)" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.9175 68.3855C75.4136 68.9479 75.831 69.8294 76.596 69.8294L79.6227 69.8294L80.021 75.7111C80.1051 76.6945 80.947 77.4514 81.9583 77.4514C82.9695 77.4514 83.8115 76.6945 83.8955 75.7111L84.2938 69.8294L87.3205 69.8294C88.0855 69.8294 88.5023 68.9479 87.999 68.3855C86.3104 66.499 84.4042 64.881 82.5975 63.1017C82.2469 62.7564 81.6697 62.7564 81.3191 63.1017C79.5124 64.881 77.6061 66.499 75.9175 68.3855Z"
        fill="url(#paint4_linear_4144_277466)"
      />
      <defs>
        <filter
          id="filter0_d_4144_277466"
          x="7"
          y="10.9501"
          width="74.1943"
          height="86.0483"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="9" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.945098 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4144_277466" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4144_277466" result="shape" />
        </filter>
        <filter
          id="filter1_d_4144_277466"
          x="31.6521"
          y="33.3913"
          width="100.696"
          height="100.696"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.7392" />
          <feGaussianBlur stdDeviation="17.1739" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.114792 0 0 0 0 0.258462 0 0 0 0 0.3625 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4144_277466" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4144_277466" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_4144_277466"
          x1="44.5746"
          y1="64.7312"
          x2="59.7951"
          y2="25.6973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary.c100} />
          <stop offset="1" stopColor={theme.colors.primary.c050} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4144_277466"
          x1="35.4668"
          y1="62.5393"
          x2="53.6734"
          y2="25.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4144_277466"
          x1="34.771"
          y1="62.8757"
          x2="20.5696"
          y2="42.6999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary.c100} />
          <stop offset="1" stopColor={theme.colors.primary.c050} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4144_277466"
          x1="66.4593"
          y1="68.7431"
          x2="88.6315"
          y2="83.6387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c400} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4144_277466"
          x1="83.7296"
          y1="64.3916"
          x2="78.3187"
          y2="79.6405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FileChooseIcon;
