import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const EmptyListJourney = (props: any) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width="207" height="177" viewBox="0 0 207 177" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="86" cy="70" r="70" fill={theme.colors.selection} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64 28C64 26.8954 64.8954 26 66 26H103.557C104.064 26 104.552 26.1925 104.922 26.5385L119.365 40.0315C119.77 40.4097 120 40.9389 120 41.4929V98C120 99.1046 119.105 100 118 100H66C64.8954 100 64 99.1046 64 98V28Z"
        fill="url(#paint0_linear_1772_5257)"
      />
      <g filter="url(#filter0_d_1772_5257)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M56 34C56 32.8954 56.8954 32 58 32H97.0027C97.5067 32 97.9921 32.1903 98.3618 32.5328L113.359 46.426C113.768 46.8045 114 47.3362 114 47.8932V106C114 107.105 113.105 108 112 108H58C56.8954 108 56 107.105 56 106V34Z"
          fill="url(#paint1_linear_1772_5257)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69 51C67.3431 51 66 52.3431 66 54C66 55.6569 67.3431 57 69 57H101C102.657 57 104 55.6569 104 54C104 52.3431 102.657 51 101 51H69ZM69 62C67.3431 62 66 63.3431 66 65C66 66.6569 67.3431 68 69 68H101C102.657 68 104 66.6569 104 65C104 63.3431 102.657 62 101 62H69ZM66 76C66 74.3431 67.3431 73 69 73H101C102.657 73 104 74.3431 104 76C104 77.6569 102.657 79 101 79H69C67.3431 79 66 77.6569 66 76ZM69 84C67.3431 84 66 85.3431 66 87C66 88.6569 67.3431 90 69 90H91.5C93.1569 90 94.5 88.6569 94.5 87C94.5 85.3431 93.1569 84 91.5 84H69Z"
        fill="url(#paint2_linear_1772_5257)"
      />
      <g filter="url(#filter1_d_1772_5257)">
        <circle cx="149" cy="105" r="23" fill="url(#paint3_linear_1772_5257)" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M159 105C159 110.523 154.523 115 149 115C143.477 115 139 110.523 139 105C139 99.4772 143.477 95 149 95C154.523 95 159 99.4772 159 105ZM150 99C150 98.4477 149.552 98 149 98C148.448 98 148 98.4477 148 99V105C148 105.379 148.214 105.725 148.553 105.894L152.553 107.894C153.047 108.141 153.647 107.941 153.894 107.447C154.141 106.953 153.941 106.353 153.447 106.106L150 104.382V99Z"
        fill="url(#paint4_linear_1772_5257)"
      />
      <defs>
        <filter
          id="filter0_d_1772_5257"
          x="38"
          y="23"
          width="94"
          height="112"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="9" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.945098 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1772_5257" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1772_5257" result="shape" />
        </filter>
        <filter
          id="filter1_d_1772_5257"
          x="91.6521"
          y="61.3913"
          width="114.696"
          height="114.696"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.7392" />
          <feGaussianBlur stdDeviation="17.1739" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.114792 0 0 0 0 0.258462 0 0 0 0 0.3625 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1772_5257" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1772_5257" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1772_5257"
          x1="85.7241"
          y1="100"
          x2="108.837"
          y2="40.7257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme.colors.primary.c100} />
          <stop offset="1" stop-color={theme.colors.primary.c050} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1772_5257"
          x1="71.8942"
          y1="96.6731"
          x2="99.5416"
          y2="40.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1772_5257"
          x1="70.8396"
          y1="97.1855"
          x2="49.2735"
          y2="66.5477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme.colors.primary.c100} />
          <stop offset="1" stop-color={theme.colors.primary.c050} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1772_5257"
          x1="126.66"
          y1="103.193"
          x2="158.533"
          y2="124.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme.colors.primary.c500} />
          <stop offset="1" stop-color={theme.colors.primary.c400} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1772_5257"
          x1="141"
          y1="108"
          x2="155.049"
          y2="113.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyListJourney;
