import styled, { css } from 'styled-components';

import { ChevronIcon } from '~/assets';
import { fadeAnimation } from '~/theme/GlobalStyles';
import { ThemeProp } from '~/theme/Themes';

interface ContainerProps {
  $collapsed?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: sticky;
  top: 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props: ContainerProps) => (props.$collapsed ? '4.25rem' : '15rem')};
  height: 100vh;
  gap: 1rem;
  padding: 1rem;
  background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  transition: width 0.3s ease-in-out;
  z-index: 1000;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.desktop}) {
    position: fixed;
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const DummyTabletContainer = styled.div`
  width: 4.25rem;
  display: none;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.desktop}) {
    display: flex;
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const TopContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CollapseChevron = styled(ChevronIcon)<ContainerProps>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${(props: ContainerProps) => (props.$collapsed ? '270deg' : '90deg')});
`;

export const CollapseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c500};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  opacity: 0;
  transition: all 0.2s linear;
  @media (min-width: ${(props: ThemeProp) => props.theme.breakpoints.desktop}) {
    :hover {
      background-color: ${(props: ThemeProp) => props.theme.colors.primary.c500};
      stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.desktop}) {
    opacity: 1;
  }
`;

export const ResizeArea = styled.div`
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1.25rem;
  transition: border-right 0.2s ease-in-out;
  border-right: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
  @media (min-width: ${(props: ThemeProp) => props.theme.breakpoints.desktop}) {
    :hover {
      ${CollapseButton} {
        opacity: 1;
      }
      border-right: 1px solid ${(props: ThemeProp) => props.theme.colors.primary.c500};
    }
  }
`;

interface AnimationProps {
  $animated?: boolean;
}

export const SectionsContainer = styled.div<AnimationProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.75rem;
  flex: 1;
  overflow: hidden;
  ${(props: AnimationProps) =>
    props.$animated &&
    css`
      animation: ${fadeAnimation} 0.3s ease-in;
    `}
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.span<AnimationProps>`
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.bold};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props: AnimationProps) =>
    props.$animated &&
    css`
      animation: ${fadeAnimation} 0.3s ease-in;
    `}
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  margin-top: 0.5rem;
`;

interface ItemContainerProps {
  $active?: boolean;
  $disabled?: boolean;
}

const getColor = (props: ThemeProp & ItemContainerProps) => {
  if (props.$disabled) {
    return props.theme.colors.neutrals.c200;
  }
  return props.$active ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.black;
};

const getStroke = (props: ThemeProp & ItemContainerProps) => {
  if (props.$disabled) {
    return props.theme.colors.neutrals.c200;
  }
  return props.$active ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.c500;
};

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  min-height: 2.25rem;
  cursor: ${(props: ItemContainerProps) => (props.$disabled ? 'auto' : 'pointer')};
  pointer-events: ${(props: ItemContainerProps) => (props.$disabled ? 'none' : 'auto')};
  color: ${(props: ThemeProp & ItemContainerProps) => getColor(props)};
  stroke: ${(props: ThemeProp & ItemContainerProps) => getStroke(props)};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  overflow: hidden;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    flex-shrink: 0;
  }
  ${(props: ItemContainerProps & ThemeProp) =>
    props.$active &&
    css`
      background: ${(props: ThemeProp) => props.theme.gradients.selection};
      border-radius: 0.25rem;
    `}
`;
