import styled from 'styled-components';

import { IconButton } from '~/components';
import { ThemeProp } from '~/theme/Themes';

export const Button = styled(IconButton).attrs((props) => {
  return { ...props, bordered: true };
})`
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  border-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};

  :focus {
    box-shadow: unset;
  }
`;
