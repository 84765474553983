import styled from 'styled-components';

import { ThemeProp } from '~/theme/Themes';

export const applyStyle = (component: any) => {
  return styled(component).attrs((props) => {
    const isError = props.status === 'error';
    return {
      ...props,
      animated: false,
      css: {
        ...props.css,
        '&': {
          marginBottom: isError ? '$5' : props.marginBottom || '$8',
          width: '100%',
        },
        '& .nextui-input-container--disabled': {
          background: '$disabledBackground !important',
          color: '$textSecondary !important',
        },
        '& .nextui-input': {
          ...(props.iconLeft && { margin: '0' }),
          '&:not(:placeholder-shown)': {
            ...(props.clearable && { width: 'calc(100% - 4.5rem)' }),
          },
        },
        '& input': {
          ...(isError && { color: '$text' }),
          fontSize: '$input',
          lineHeight: '$sm2',

          '&:disabled': {
            background: '$disabledBackground !important',
            color: '$textSecondary !important',
            opacity: '1 !important',
          },
          '&:focus::placeholder': {
            opacity: 1,
          },
        },
        '& .nextui-input-block-label': {
          color: '$text',
          fontSize: '$sm',
          lineHeight: '$xs',
          paddingLeft: '$4',
        },
        '& .nextui-input-container': {
          marginTop: props?.containerMarginTop || '$1',
          height: '$15',
          border: `$borderWeights$normal solid ${isError ? '$error' : '$inputBorder'}`,
          borderRadius: '$xxs',
        },
        '& .nextui-input-container .nextui-input-wrapper': {
          background: '$inputBackground',
          borderRadius: '$xxs',
        },
        '.nextui-input-wrapper--disabled': {
          background: '$disabledBackground !important',
          color: '$textSecondary !important',
          opacity: '1 !important',
        },
        '& .nextui-input-helper-text-container': {
          position: 'unset',
          marginTop: '0',
        },
        '& .nextui-input-helper-text-container p': {
          fontSize: 'calc($sm - 1px)',
          lineHeight: '$xs3',
        },
      },
    };
  })`
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices: large password dots fix */
      & input[type='password']:not(:placeholder-shown) {
        font: small-caption;
        font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
      }
    }
  `;
};
