import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const EmptyListIcon = (props: any) => {
  const theme = useContext(ThemeContext);
  return (
    <svg height="156" width="218" fill="none" viewBox="0 0 218 156" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="95" cy="70" fill={theme.colors.selection} r="70" />
      <circle cx="158.5" cy="103.5" fill="url(#paint7_linear_1170_4232)" fillOpacity="0.4" r="6.5" />
      <g>
        <rect height="42" width="145" fill="white" rx="4" x="22" y="15" />
        <rect height="42" width="145" fill="url(#paint0_linear_1170_4232)" rx="4" x="22" y="15" />
        <rect
          height="41.2698"
          width="144.27"
          rx="3.63488"
          stroke="#F4F4F4"
          strokeWidth="0.730243"
          x="22.3651"
          y="15.3651"
        />
      </g>
      <g>
        <rect height="42" width="145" fill="white" rx="4" x="22" y="67" />
        <rect height="42" width="145" fill="url(#paint3_linear_1170_4232)" rx="4" x="22" y="67" />
        <rect
          height="41.2698"
          width="144.27"
          rx="3.63488"
          stroke="#F4F4F4"
          strokeWidth="0.730243"
          x="22.3651"
          y="67.3651"
        />
      </g>
      <g>
        <circle cx="160" cy="105" fill="url(#paint6_linear_1170_4232)" r="23" />
      </g>
      <rect height="8" width="84" fill="url(#paint1_linear_1170_4232)" opacity="0.7" rx="4" x="65" y="32" />
      <rect height="24" width="24" fill={theme.colors.primary.c500} opacity="0.1" rx="4.38146" x="31" y="24" />
      <rect height="8" width="84" fill="url(#paint4_linear_1170_4232)" opacity="0.7" rx="4" x="65" y="84" />
      <rect height="24" width="24" fill={theme.colors.primary.c500} opacity="0.1" rx="4.38146" x="31" y="76" />
      <path
        d="M44.302 35.7034C45.3067 35.2187 46 34.1903 46 32.9999C46 31.3431 44.6568 29.9999 43 29.9999C41.3431 29.9999 40 31.3431 40 32.9999C40 34.1902 40.6931 35.2186 41.6979 35.7034C39.9917 36.253 38.7572 37.8536 38.7572 39.7425C38.7572 42.0857 47.2425 42.0857 47.2425 39.7425C47.2425 37.8536 46.0081 36.253 44.302 35.7034Z"
        fill="url(#paint2_linear_1170_4232)"
        fillRule="evenodd"
      />
      <path
        d="M44.302 87.7034C45.3067 87.2187 46 86.1903 46 84.9999C46 83.3431 44.6568 81.9999 43 81.9999C41.3431 81.9999 40 83.3431 40 84.9999C40 86.1902 40.6931 87.2186 41.6979 87.7034C39.9917 88.253 38.7572 89.8536 38.7572 91.7425C38.7572 94.0857 47.2425 94.0857 47.2425 91.7425C47.2425 89.8536 46.0081 88.253 44.302 87.7034Z"
        fill="url(#paint5_linear_1170_4232)"
        fillRule="evenodd"
      />
      <path
        d="M164.823 103.324C164.823 106.54 162.216 109.147 159 109.147C155.784 109.147 153.177 106.54 153.177 103.324C153.177 100.107 155.784 97.5002 159 97.5002C162.216 97.5002 164.823 100.107 164.823 103.324ZM162.879 111.251C161.709 111.825 160.392 112.147 159 112.147C154.127 112.147 150.177 108.197 150.177 103.324C150.177 98.4505 154.127 94.5002 159 94.5002C163.873 94.5002 167.823 98.4505 167.823 103.324C167.823 105.723 166.866 107.898 165.312 109.489L168.171 113.063C168.689 113.71 168.584 114.654 167.937 115.171C167.29 115.689 166.346 115.584 165.829 114.937L162.879 111.251Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        d="M164.823 103.324C164.823 106.54 162.216 109.147 159 109.147C155.784 109.147 153.177 106.54 153.177 103.324C153.177 100.107 155.784 97.5002 159 97.5002C162.216 97.5002 164.823 100.107 164.823 103.324ZM162.879 111.251C161.709 111.825 160.392 112.147 159 112.147C154.127 112.147 150.177 108.197 150.177 103.324C150.177 98.4505 154.127 94.5002 159 94.5002C163.873 94.5002 167.823 98.4505 167.823 103.324C167.823 105.723 166.866 107.898 165.312 109.489L168.171 113.063C168.689 113.71 168.584 114.654 167.937 115.171C167.29 115.689 166.346 115.584 165.829 114.937L162.879 111.251Z"
        fill="url(#paint8_linear_1170_4232)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="61.7356"
          x2="65.8687"
          y1="50.7404"
          y2="12.4132"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="63.6667"
          x2="157.667"
          y1="39.3333"
          y2="25.3333"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="46.5356"
          x2="48.3571"
          y1="41.3571"
          y2="28"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="61.7356"
          x2="65.8687"
          y1="102.74"
          y2="64.4132"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="63.6667"
          x2="157.667"
          y1="91.3333"
          y2="77.3333"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="46.5356"
          x2="48.3571"
          y1="93.3571"
          y2="80"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="137.66"
          x2="169.533"
          y1="103.193"
          y2="124.606"
        >
          <stop stopColor={theme.colors.primary.c500} />
          <stop offset="1" stopColor={theme.colors.primary.c500} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="159.944"
          x2="166.835"
          y1="102.614"
          y2="112.384"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1170_4232"
          gradientUnits="userSpaceOnUse"
          x1="161.245"
          x2="173.419"
          y1="103.961"
          y2="118.231"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyListIcon;
